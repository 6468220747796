import PropTypes from 'prop-types'
import arrowLeft from '../assets/icons/arrowLeft.png';
import arrowRight from '../assets/icons/arrowRight.png';
import { useState, useRef, useEffect } from 'react';

const Slider = ({
  elements
}) => {
  const [activePostion, setActivePostion] = useState(0)
  const [isGoingForward, setIsGoingForward] = useState(true)
  const ref = useRef()

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      const sliderWidth = ref.current.clientWidth
      const newPositionForward = activePostion + sliderWidth
      if (sliderWidth * elements.length > newPositionForward && isGoingForward) setActivePostion(newPositionForward)
      else {
        if (isGoingForward) setIsGoingForward(false)
        const newPosition = activePostion - sliderWidth
        if (newPosition >= 0) setActivePostion(newPosition)
        else {
          setIsGoingForward(true)
          setActivePostion(newPositionForward)
        }
      }
    }, 6000);
    return () => clearInterval(sliderInterval); //This is important
  }, [activePostion])
  

  const onNextSliderItem = (index) => {
    const sliderWidth = ref.current.clientWidth
    const newPosition = activePostion + sliderWidth
    if (sliderWidth * elements.length > newPosition) {
      setActivePostion(newPosition)
    }
  }

  const onPreviousSliderItem = (index) => {
    const sliderWidth = ref.current.clientWidth
    const newPosition = activePostion - sliderWidth
    if (newPosition >= 0) {
      setActivePostion(newPosition)
    }
  }

  if (elements.length === 0) return (<></>)
  return (
    <div className="slider_container">
      {/* Arrow Left */}
      <img style={{ width: '2rem', objectFit: 'contain', zIndex: 99 }} onClick={onPreviousSliderItem} src={arrowLeft} alt='Fecha' />
      {/* Content */}
      <div className='slider_container__items_container' ref={ref}>
        <div style={{ width: '100%', transform: `translateX(-${activePostion}px)` }}>
          {elements}
        </div>
      </div>
      {/* Arrow Right */}
      <img style={{ width: '2rem', objectFit: 'contain', zIndex: 99 }} onClick={onNextSliderItem} src={arrowRight} alt='Fecha' />
    </div>
  )
}

export default Slider

Slider.propTypes = {
  elements: PropTypes.any
}