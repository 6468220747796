import React from 'react';
import PropTypes from 'prop-types';
import ListLinks from './ListLinks';

const Navbar = ({ open, links }) => {
  return (
    <nav open={open}>
      <ListLinks links={links} />
    </nav>
  );
};

export default Navbar;

Navbar.propTypes = {
  open: PropTypes.bool.isRequired,
  links: PropTypes.arrayOf(PropTypes.object).isRequired,
};
