import internmentPhoto from '../assets/services/foto habitacion internacion .jpg';
import internmentIcon from '../assets/services/internmentIcon.png';
import ReturnPage from '../components/ReturnPage';
import ContainerInformationImages from '../components/ContainerInformationImages';
import PropTypes from 'prop-types';
import MenuContainer from '../components/MenuContainer';
import { Helmet } from 'react-helmet';
import homeImage from '../assets/home/homeImage.png';

const Internment = ({ open }) => {
  const title = 'Internación';
  const paragraph = ['Para asegurar la calidad de su atención es importante conocer los motivos de su internación. Si está siendo internado en la Clínica por primera vez, no se olvide presentar todos los documentos solicitados, asegúrese de comunicarnos si pertenece algún seguro médico.', 'Es esencial una comunicación directa con su médico tratante, el paciente o su acompañante debe informarnos sobre sus antecedentes médicos, restricciones alimenticias, alergias y si está recibiendo algún tratamiento con medicamentos en el momento de su internación. Todo el personal administrativo y personal médico, están prestos a brindarle una atención cálida y profesional.'];

  return (
    <>
    <Helmet>
      <title>Clínica Copacabana | Internación</title>
      <meta
        name="description"
        content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas."
      />
      <meta name="keywords" content="cochabamba, clinica, salud, hospital, doctores, medicos, emergencias" />
      <meta name="robots" content="index, follow"/>
      <meta name="googlebot" content="index, follow"/>
      <link rel="canonical" href={`${process.env.REACT_APP_FRONTENDURL}`} />
      <meta property="og:title" content="Clínica Copacabana" />
      <meta property="og:description" content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas." />
      <meta property="og:img" content={`${process.env.REACT_APP_FRONTENDURL}/${homeImage}`} />
      <meta property="og:url" content={`${process.env.REACT_APP_FRONTENDURL}/admisiones`} />
    </Helmet>
    {!open ? (
    <section className="installations-container">
      <ReturnPage />
      <ContainerInformationImages isServiceTitle={true} title={title} photo={internmentPhoto} icon={internmentIcon} paragraph={paragraph} />
    </section>) : 
    (
      <MenuContainer />
    )}
    </>
  )
}

export default Internment;

Internment.propTypes = {
  open: PropTypes.bool.isRequired,
};
