import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/header-footer/logo.png';
import Logo from './Logo';
import MenuButton from './MenuButton';
import Navbar from './Navbar';
import PropTypes from 'prop-types';

const Header = ({ open, handleClick }) => {
  const links = [
    {
      id: 1,
      name: 'Inicio',
      path: '/',
    },
    {
      id: 2,
      name: 'Servicios',
      path: '/servicios',
    },
    {
      id: 3,
      name: 'Planes',
      path: '/planes',
    },
    {
      id: 4,
      name: 'Instalaciones',
      path: '/instalaciones',
    },
    {
      id: 5,
      name: 'Admisión',
      path: '/admision',
    },
    {
      id: 6,
      name: 'Contacto',
      path: '/contacto',
    },
  ];

  return (
    <header>
      <Link to="/" className='logo'>
        <img className="logo-header" src={logo} alt="Logo" />
      </Link>
      <Navbar open={open} links={links} />
      <MenuButton open={open} handleClick={handleClick} />
    </header>
  );
}

export default Header;

Header.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};
