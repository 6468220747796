import Card from '../components/Card';
import queriesIcon from '../assets/services/queriesIcon.png';
import maternityIcon from '../assets/services/maternityIcon.png';
import specialtiesIcon from '../assets/services/specialtiesIcon.png';
import intensiveCareIcon from '../assets/services/intensiveCareIcon.png';
import internmentIcon from '../assets/services/internmentIcon.png';
import careAndPreventionIcon from '../assets/services/careAndPreventionIcon.png';
import emergenciesIcon from '../assets/services/emergenciesIcon.png';
import diagnosticStudiesIcon from '../assets/services/diagnosticStudiesIcon.png';
import PropTypes from 'prop-types';
import MenuContainer from '../components/MenuContainer';
import Title from '../components/Title';
import { Helmet } from 'react-helmet';
import homeImage from '../assets/home/homeImage.png';

const Services = ({ open, setOpen }) => {
  const cards = [
    {
      id: 1,
      title: 'Consultas',
      image: queriesIcon,
      path: '/servicios/consultas',
      section: 'services',
    },
    {
      id: 2,
      title: 'Maternidad',
      image: maternityIcon,
      path: '/servicios/maternidad',
      section: 'services',
    },
    {
      id: 3,
      title: 'Especialidades',
      image: specialtiesIcon,
      path: '/servicios/especialidades',
      section: 'services',
    },
    {
      id: 4,
      title: 'Cuidados Intensivos',
      image: intensiveCareIcon,
      path: '/servicios/cuidados-intensivos',
      section: 'services',
    },
    {
      id: 5,
      title: 'Internación',
      image: internmentIcon,
      path: '/servicios/internacion',
      section: 'services',
    },
    {
      id: 6,
      title: 'Cuidado y Prevención',
      image: careAndPreventionIcon,
      path: '/servicios/cuidado-y-prevencion',
      section: 'services',
    },
    {
      id: 7,
      title: 'Emergencias',
      image: emergenciesIcon,
      path: '/servicios/emergencias',
      section: 'services',
    },
    {
      id: 8,
      title: 'Estudios Diagnósticos',
      image: diagnosticStudiesIcon,
      path: '/servicios/estudios-diagnosticos',
      section: 'services',
    },
  ];

  return (
    <>
    <Helmet>
      <title>Clínica Copacabana | Servicios</title>
      <meta
        name="description"
        content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas."
      />
      <meta name="keywords" content="cochabamba, clinica, salud, hospital, doctores, medicos, emergencias" />
      <meta name="robots" content="index, follow"/>
      <meta name="googlebot" content="index, follow"/>
      <link rel="canonical" href={`${process.env.REACT_APP_FRONTENDURL}`} />
      <meta property="og:title" content="Clínica Copacabana" />
      <meta property="og:description" content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas." />
      <meta property="og:img" content={`${process.env.REACT_APP_FRONTENDURL}/${homeImage}`} />
      <meta property="og:url" content={`${process.env.REACT_APP_FRONTENDURL}/admisiones`} />
    </Helmet>
    {!open ? (<>
    <div className='services_page'>
      <Title title={'Servicios'} />
      <section className='services-container'>
          {cards.map((card) => (
            <Card
              key={card.id}
              title={card.title}
              image={card.image}
              path={card.path}
              section={card.section}
            />
          ))}
      </section>
    </div>
    </>) : 
    (
      <MenuContainer setOpen={setOpen} />
    )}
    </>
  )
};

export default Services;

Services.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.function
};
