import intensiveCarePhoto from '../assets/services/cuidados intensivos.jpg';
import intensiveCareIcon from '../assets/services/intensiveCareIcon.png';
import ReturnPage from '../components/ReturnPage';
import ContainerInformationImage from '../components/ContainerInformationImages';
import PropTypes from 'prop-types';
import MenuContainer from '../components/MenuContainer';
import { Helmet } from 'react-helmet';
import homeImage from '../assets/home/homeImage.png';

const IntensiveCare = ({ open }) => {
  const title = 'Cuidados Intensivos';
  const paragraph = ['La Clínica Copacabana cuenta con Unidad de Cuidados Intensivos:', ['UTI Adultos', 'UTI Pediátrico', 'UTI Neonatal'], 'Nuestro equipo médico y de enfermería tiene la formación y la experiencia necesaria para el cuidado adecuado de cada paciente que requiera este servicio.'];

  return (
    <>
    <Helmet>
      <title>Clínica Copacabana | Cuidados Intensivos</title>
      <meta
        name="description"
        content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas."
      />
      <meta name="keywords" content="cochabamba, clinica, salud, hospital, doctores, medicos, emergencias" />
      <meta name="robots" content="index, follow"/>
      <meta name="googlebot" content="index, follow"/>
      <link rel="canonical" href={`${process.env.REACT_APP_FRONTENDURL}`} />
      <meta property="og:title" content="Clínica Copacabana" />
      <meta property="og:description" content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas." />
      <meta property="og:img" content={`${process.env.REACT_APP_FRONTENDURL}/${homeImage}`} />
      <meta property="og:url" content={`${process.env.REACT_APP_FRONTENDURL}/admisiones`} />
    </Helmet>
    {!open ? (
    <section className="installations-container">
      <ReturnPage />
      <ContainerInformationImage isServiceTitle={true} title={title} photo={intensiveCarePhoto} icon={intensiveCareIcon} paragraph={paragraph} />
    </section>) : 
    (
      <MenuContainer />
    )}
    </>
  )
}

export default IntensiveCare;

IntensiveCare.propTypes = {
  open: PropTypes.bool.isRequired,
};
