import maternityPhoto from '../assets/services/maternityPhoto.png';
import maternityIcon from '../assets/services/maternityIcon.png';
import ReturnPage from '../components/ReturnPage';
import ContainerInformationImages from '../components/ContainerInformationImages';
import PropTypes from 'prop-types';
import MenuContainer from '../components/MenuContainer';
import { Helmet } from 'react-helmet';
import homeImage from '../assets/home/homeImage.png';

const Maternity = ({ open }) => {
  const title = 'Maternidad';
  const paragraph = ['La  Clínica Copacabana nació como centro maternológico y hasta el día de hoy mantiene una sólida tradición, en el cuidado de madres y sus bebés, el mejor testimonio es el de aquellos que nacieron en la Copacabana.', 'Pensando en su familia, ponemos a su disposición el Plan Maternidad, con el propósito de beneficiarlo con una atención médica y hospitalaria de primera, y a un precio justo y accesible para todos, conozca nuestro plan.'];

  return (
    <>
    <Helmet>
      <title>Clínica Copacabana | Maternidad</title>
      <meta
        name="description"
        content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas."
      />
      <meta name="keywords" content="cochabamba, clinica, salud, hospital, doctores, medicos, emergencias" />
      <meta name="robots" content="index, follow"/>
      <meta name="googlebot" content="index, follow"/>
      <link rel="canonical" href={`${process.env.REACT_APP_FRONTENDURL}`} />
      <meta property="og:title" content="Clínica Copacabana" />
      <meta property="og:description" content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas." />
      <meta property="og:img" content={`${process.env.REACT_APP_FRONTENDURL}/${homeImage}`} />
      <meta property="og:url" content={`${process.env.REACT_APP_FRONTENDURL}/admisiones`} />
    </Helmet>
    {!open ? (
    <section className="installations-container">
      <ReturnPage />
      <ContainerInformationImages isServiceTitle={true} title={title} icon={maternityIcon} paragraph={paragraph} photo={maternityPhoto} />
    </section>) : 
    (
      <MenuContainer />
    )}
    </>
  )
}

export default Maternity;

Maternity.propTypes = {
  open: PropTypes.bool.isRequired,
};
