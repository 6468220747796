import emergenciesPhoto from '../assets/services/emergenciesPhoto.png';
import emergenciesIcon from '../assets/services/emergenciesIcon.png';
import ReturnPage from '../components/ReturnPage';
import ContainerInformationImages from '../components/ContainerInformationImages';
import PropTypes from 'prop-types';
import MenuContainer from '../components/MenuContainer';
import { Helmet } from 'react-helmet';
import homeImage from '../assets/home/homeImage.png';

const Internment = ({ open }) => {
  const title = 'Emergencias';
  const paragraph = ['Estamos al servicio de la comunidad, nuestro servicio de urgencias atiende las 24 horas del día, estamos a su disposición los 365 días del año.'];

  return (
    <>
    <Helmet>
      <title>Clínica Copacabana | Emergencias</title>
      <meta
        name="description"
        content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas."
      />
      <meta name="keywords" content="cochabamba, clinica, salud, hospital, doctores, medicos, emergencias" />
      <meta name="robots" content="index, follow"/>
      <meta name="googlebot" content="index, follow"/>
      <link rel="canonical" href={`${process.env.REACT_APP_FRONTENDURL}`} />
      <meta property="og:title" content="Clínica Copacabana" />
      <meta property="og:description" content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas." />
      <meta property="og:img" content={`${process.env.REACT_APP_FRONTENDURL}/${homeImage}`} />
      <meta property="og:url" content={`${process.env.REACT_APP_FRONTENDURL}/admisiones`} />
    </Helmet>
    {!open ? (
    <section className="installations-container">
      <ReturnPage />
      <ContainerInformationImages isServiceTitle={true} title={title} photo={emergenciesPhoto} icon={emergenciesIcon} paragraph={paragraph} />
    </section>) : 
    (
      <MenuContainer />
    )} 
    </>
  )
}

export default Internment;

Internment.propTypes = {
  open: PropTypes.bool.isRequired,
};
