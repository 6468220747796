import diagnosticStudiesPhoto from '../assets/services/diagnosticStudiesPhoto.png';
import diagnosticStudiesIcon from '../assets/services/diagnosticStudiesIcon.png';
import ReturnPage from '../components/ReturnPage';
import ContainerInformationImages from '../components/ContainerInformationImages';
import PropTypes from 'prop-types';
import MenuContainer from '../components/MenuContainer';
import { Helmet } from 'react-helmet';
import homeImage from '../assets/home/homeImage.png';

const DiagnosticStudies = ({ open }) => {
  const title = 'Estudios Diagnósticos';
  const paragraph = ['Centro de Imagenología', ['Tomografía', 'Ecografía', 'Rayos X', 'Video Endoscopia', 'Video Colposcopia', 'Ecografía Doppler Color', 'Ecografía de Partes Blandas', 'Ecografía Ginecológico - Obstétrico', 'Intensificador de imágenes para procedimientos quirúrgicos'], 'Estudios Urología', ['Uroflujometría', 'Urodinamia', 'Biopsia de próstata transrectal bajo ecografía', 'Equipo láser Holmium de alta potencia para cálculos renales']];

  return (
    <>
    <Helmet>
      <title>Clínica Copacabana | Estudios y Diagnosticos</title>
      <meta
        name="description"
        content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas."
      />
      <meta name="keywords" content="cochabamba, clinica, salud, hospital, doctores, medicos, emergencias" />
      <meta name="robots" content="index, follow"/>
      <meta name="googlebot" content="index, follow"/>
      <link rel="canonical" href={`${process.env.REACT_APP_FRONTENDURL}`} />
      <meta property="og:title" content="Clínica Copacabana" />
      <meta property="og:description" content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas." />
      <meta property="og:img" content={`${process.env.REACT_APP_FRONTENDURL}/${homeImage}`} />
      <meta property="og:url" content={`${process.env.REACT_APP_FRONTENDURL}/admisiones`} />
    </Helmet>
    {!open ? (
    <section className="installations-container">
      <ReturnPage />
      <ContainerInformationImages isServiceTitle={true} title={title} icon={diagnosticStudiesIcon} paragraph={paragraph} photo={diagnosticStudiesPhoto} />
    </section>) : 
    (
      <MenuContainer />
    )}
    </>
  )
}

export default DiagnosticStudies;

DiagnosticStudies.propTypes = {
  open: PropTypes.bool.isRequired,
};
