import hamburger from '../assets/header-footer/hamburger.png';
import close from '../assets/header-footer/close.png';
import PropTypes from 'prop-types';

const MenuButton = ({ open, handleClick }) => {
  return !open ? (
    <button type="button" className='hamburger' onClick={handleClick}>
      <img src={hamburger} alt="menu icon" />
    </button>
  ): (
    <button type="button" onClick={handleClick}>
      <img src={close} alt="x icon" />
    </button>
  )
};

export default MenuButton;

MenuButton.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};
