import ListLinks from "./ListLinks";
import PropTypes from 'prop-types';

const MenuContainer = (props) => {
  const links = [
    {
      id: 1,
      name: 'Inicio',
      path: '/',
    },
    {
      id: 2,
      name: 'Servicios',
      path: '/servicios',
    },
    {
      id: 3,
      name: 'Planes',
      path: '/planes',
    },
    {
      id: 4,
      name: 'Instalaciones',
      path: '/instalaciones',
    },
    {
      id: 5,
      name: 'Admisión',
      path: '/admision',
    },
    {
      id: 6,
      name: 'Contacto',
      path: '/contacto',
    },
  ];

  return (
    <section className='menu-container'>
      <ListLinks links={links} onClickLink={() => { props.setOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' }) }} nameSection='menu'/>
    </section>
  );
};

export default MenuContainer;

MenuContainer.propTypes = {
  setOpen: PropTypes.function
}