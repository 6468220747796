import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import facebook from '../assets/header-footer/facebook.png';
import instagram from '../assets/header-footer/instagram.png';
import FacebookInstagram from './FacebookInstagram';

const ListLinks = ({ links, nameSection, onClickLink }) => {
  return (
    <ul className={nameSection  === 'menu' ? 'list-links-menu' : 'list-links'}>
      {links.map((link) => (
        <li key={link.id}>
          <Link to={link.path} onClick={onClickLink}>{link.name}</Link>
        </li>
      ))}
      <li>
        <FacebookInstagram facebookIcon={facebook} instagramIcon={instagram} />
      </li>
    </ul>
  );
}

export default ListLinks;

ListLinks.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object).isRequired,
  nameSection: PropTypes.string,
  onClickLink: PropTypes.function
};
