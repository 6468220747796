import PropTypes from 'prop-types';
import Paragraphs from "./Paragraphs"
import Title from "./Title"

const SliderItem = ({
  img,
  title,
  paragraph
}) => {
  return (
    <div style={{ minWidth: '100%', display: 'inline-block', padding: '0 1rem' }}>
      <div style={{ display: 'flex' }} className='container_home_box' >
        <div className='container_home_content'>
          <img src={img} alt='Tomografía computarizada' />
          <div className='container_home_title_paragraph' style={{ flexDirection: 'column' }}>
            <Title title={title} isWhite={true} />
            <Paragraphs paragraphs={paragraph} />
          </div>
        </div>
        <div className='orange-line'></div>
      </div>
    </div>
  )
}

export default SliderItem

SliderItem.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
}