import PropTypes from 'prop-types';
import arrowLeft from '../assets/icons/arrowLeft.png';
import arrowRight from '../assets/icons/arrowRight.png';
import Slider from './Slider';

const ContainerImagesTop = (props) => {
  const { title, photo } = props;
  console.log('THIS IS PHOTO: ', photo)
  return (
    <div className='container-images-top'>
      {Array.isArray(photo)
        ? <Slider
            elements={[
              <img key={''} className='photos' style={{ width: '100%', padding: '0 1rem' }} src={photo[0]} alt={`Fotos de ${title}`} />,
              <img key={''} className='photos' style={{ width: '100%', padding: '0 1rem' }} src={photo[1]} alt={`Fotos de ${title}`} />,
              <img key={''} className='photos' style={{ width: '100%', padding: '0 1rem' }} src={photo[2]} alt={`Fotos de ${title}`} />,
              <img key={''} className='photos' style={{ width: '100%', padding: '0 1rem' }} src={photo[3]} alt={`Fotos de ${title}`} />
            ]}
          />
        : <img className='photos' src={photo} alt={`Fotos de ${title}`} />
      }
    </div>
  );
};

export default ContainerImagesTop;

ContainerImagesTop.propTypes = {
  title: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
};
