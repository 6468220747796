import specialtiesPhoto from '../assets/services/specialtiesPhoto.png';
import specialtiesIcon from '../assets/services/specialtiesIcon.png';
import ReturnPage from '../components/ReturnPage';
import ContainerInformationImages from '../components/ContainerInformationImages';
import PropTypes from 'prop-types';
import MenuContainer from '../components/MenuContainer';
import { Helmet } from 'react-helmet';
import homeImage from '../assets/home/homeImage.png';

const Specialties = ({ open }) => {
  const title = 'Especialidades';
  const paragraph = [['Cardiología', 'Cirugía General', 'Cirugía Plástica - Reconstructiva', 'Cirugía Gastroenterológica y Coloproctológica', 'Cirugía de trauma y Percutánea Mínimamente Invasiva' , 'Dermatología', 'Endocrinología', 'Gastroenterología', 'Ginecología', 'Medicina Interna', 'Medicina General', 'Nefrología', 'Neumología', 'Neurocirugía', 'Nutrición', 'Oftalmología', 'Pediatría', 'Proctología', 'Reumatología', 'Terápia Neonatal', 'Traumatología', 'Urología']];

  return (
    <>
    <Helmet>
      <title>Clínica Copacabana | Especialidades</title>
      <meta
        name="description"
        content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas."
      />
      <meta name="keywords" content="cochabamba, clinica, salud, hospital, doctores, medicos, emergencias" />
      <meta name="robots" content="index, follow"/>
      <meta name="googlebot" content="index, follow"/>
      <link rel="canonical" href={`${process.env.REACT_APP_FRONTENDURL}`} />
      <meta property="og:title" content="Clínica Copacabana" />
      <meta property="og:description" content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas." />
      <meta property="og:img" content={`${process.env.REACT_APP_FRONTENDURL}/${homeImage}`} />
      <meta property="og:url" content={`${process.env.REACT_APP_FRONTENDURL}/admisiones`} />
    </Helmet>
    {!open ? (
    <section className="installations-container">
      <ReturnPage />
      <ContainerInformationImages isServiceTitle={true} title={title} icon={specialtiesIcon} paragraph={paragraph} photo={specialtiesPhoto} />
    </section>) : 
    (
      <MenuContainer />
    )}
    </>
  )
}

export default Specialties;

Specialties.propTypes = {
  open: PropTypes.bool.isRequired,
};
