import queriesPhoto from '../assets/services/queriesPhoto.png';
import queriesIcon from '../assets/services/queriesIcon.png';
import ReturnPage from '../components/ReturnPage';
import ContainerInformationImages from '../components/ContainerInformationImages';
import PropTypes from 'prop-types';
import MenuContainer from '../components/MenuContainer';
import { Helmet } from 'react-helmet';
import homeImage from '../assets/home/homeImage.png';

const Queries = ({ open }) => {
  const title = 'Consultas';
  const paragraph = ['Clínica Copacabana S.R.L., le ofrece servicio de consulta médica en medicina general las 24 horas del día. Para consultas de especialidad médica, usted deberá elegir el profesional médico de su preferencia y preguntar acerca de los horarios de atención, las consultas de urgencia son atendidas inmediatamente por el especialista que corresponda en cada caso y a cualquier hora del día.'];

  return (
    <>
    <Helmet>
      <title>Clínica Copacabana | Consultas</title>
      <meta
        name="description"
        content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas."
      />
      <meta name="keywords" content="cochabamba, clinica, salud, hospital, doctores, medicos, emergencias" />
      <meta name="robots" content="index, follow"/>
      <meta name="googlebot" content="index, follow"/>
      <link rel="canonical" href={`${process.env.REACT_APP_FRONTENDURL}`} />
      <meta property="og:title" content="Clínica Copacabana" />
      <meta property="og:description" content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas." />
      <meta property="og:img" content={`${process.env.REACT_APP_FRONTENDURL}/${homeImage}`} />
      <meta property="og:url" content={`${process.env.REACT_APP_FRONTENDURL}/admisiones`} />
    </Helmet>
    {!open ? (
    <section className="installations-container">
      <ReturnPage />
      <ContainerInformationImages isServiceTitle={true} title={title} icon={queriesIcon} paragraph={paragraph} photo={queriesPhoto} />
    </section>) : 
    (
      <MenuContainer />
    )}
    </>
  )
}

export default Queries;

Queries.propTypes = {
  open: PropTypes.bool.isRequired,
};
