import { Link } from 'react-router-dom';
import WhatsappButton from '../components/WhatsappButton';
import whatsappButton from '../assets/home/whatsappButton.png';
import callImage from '../assets/home/callImage.png';
import arrowLeft from '../assets/icons/arrowLeft.png';
import arrowRight from '../assets/icons/arrowRight.png';
import homeImage from '../assets/home/homeImage.png';
import Tomographie from '../assets/home/tomografia.jpg';
import womensRooms from '../assets/home/FOTO AMBIENTES MUJER Y MATERNIDAD.jpg';
import externalConsult from '../assets/home/consulta Externa.jpg';
import operatingRoom from '../assets/home/quirofano.jpg';
import waitingRoom from '../assets/home/sala de espera.jpg';
import Title from '../components/Title';
import Paragraphs from '../components/Paragraphs';
import plansIcon from '../assets/home/plansIcon.png';
import servicesIcon from '../assets/home/servicesIcon.png';
import installationsIcon from '../assets/home/installationsIcon.png';
import admissionIcon from '../assets/home/admissionIcon.png';
import Card from '../components/Card';
import PropTypes from 'prop-types';
import MenuContainer from '../components/MenuContainer';
import Slider from '../components/Slider';
import SliderItem from '../components/SliderItem';
import { Helmet } from 'react-helmet';


const Home = ({ open }) => {
  const sliderContent = [
    {
      title: 'Tomografía',
      paragraph: 'En Clínica Copacabana contamos con un equipo de última generación que brinda imágenes en alta calidad. Garantizando resultados confiables en la detección de enfermedades.',
      img: homeImage
    },
    {
      title: 'Consulta externa',
      paragraph: 'Nuestro servicio de consulta externa es atendido por profesionales altamente calificados en diferentes especialidades',
      img: homeImage
    },
    {
      title: 'Mujer y maternidad',
      paragraph: 'Contamos con antención especializada en Ginecología y Obstetricia',
      img: homeImage
    },
    {
      title: 'Quirófanos',
      paragraph: 'Nuestros Quirófanos están equipados con tecnología de punta',
      img: homeImage
    },
    {
      title: 'Sala de espera',
      paragraph: 'Áreas de espera conformables para nuestros pacientes y sus familiares',
      img: homeImage
    },
  ]
  const title = 'Tomografía computarizada';
  const paragraph = ['En Clínica Copacabana contamos con un equipo de última generación que brinda imágenes en alta calidad. Garantizando resultados confiables en la deteccion de enfermedades.'];
  const cards = [
    {
      id: 1,
      title: 'Servicios hospitalarios',
      image: servicesIcon,
      path: '/servicios',
    },
    {
      id: 2,
      title: 'Planes y medicina preventiva',
      image: plansIcon,
      path: '/planes',
    },
    {
      id: 3,
      title: 'Instalaciones',
      image: installationsIcon,
      path: '/instalaciones',
    },
    {
      id: 4,
      title: 'Admisión',
      image: admissionIcon,
      path: '/admision',
    }
  ];

  return (
    <>
    <Helmet>
      <title>Clínica Copacabana</title>
      <meta
        name="description"
        content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas."
      />
      <meta name="keywords" content="cochabamba, clinica, salud, hospital, doctores, medicos, emergencias" />
      <meta name="robots" content="index, follow"/>
      <meta name="googlebot" content="index, follow"/>
      <link rel="canonical" href={`${process.env.REACT_APP_FRONTENDURL}`} />
      <meta property="og:title" content="Clínica Copacabana" />
      <meta property="og:description" content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas." />
      <meta property="og:img" content={`%${process.env.REACT_APP_FRONTENDURL}%/${homeImage}`} />
      <meta property="og:url" content={`%${process.env.REACT_APP_FRONTENDURL}%/admisiones`} />
    </Helmet>
    {!open ? (
    <section className='home-container'>
      <div className='container_top_home'>
        <div className='container_top_home_black'>
          <p>Más de <span>69 años </span>cuidando<br/>a los Cochabambinos</p>
          <button className='button_history' type='button'>
            <Link to='/nuestra-historia'>Nuestra historia</Link>
          </button>
        </div>
      </div>
      <div className='container_cards_home'>
        {cards.map((card) => (
            <Card
              key={card.id}
              title={card.title}
              image={card.image}
              path={card.path}
            />
          ))}
      </div>
      <div className='container_contact_home'>
        <WhatsappButton whatsappButton={whatsappButton} />
        <img className='contact_button' style={{ paddingTop: '2%' }} src={callImage} alt='Línea de Emergencia' />     
      </div>
      <div className='container_home_bottom'>
        <Slider
          elements={[
            <SliderItem
              key={1}
              img={Tomographie}
              title='Tomografía'
              paragraph={['En Clínica Copacabana contamos con un equipo de última generación que brinda imágenes en alta calidad. Garantizando resultados confiables en la detección de enfermedades.']}
            />,
            <SliderItem
              key={2}
              img={externalConsult}
              title='Consulta externa'
              paragraph={['Nuestro servicio de consulta externa es atendido por profesionales altamente calificados en diferentes especialidades.']}
            />,
            <SliderItem
              key={3}
              img={womensRooms}
              title='Mujer y maternidad'
              paragraph={['Contamos con antención especializada en Ginecología y Obstetricia.']}
            />,
            <SliderItem
              key={4}
              img={operatingRoom}
              title='Quirófanos'
              paragraph={['Nuestros Quirófanos están equipados con tecnología de punta.']}
            />,
            <SliderItem
              key={5}
              img={waitingRoom}
              title='Sala de espera'
              paragraph={['Áreas de espera conformables para nuestros pacientes y sus familiares.']}
            />
          ]}
        />
      </div>
    </section>) : 
    (
      <MenuContainer />
    )}
    </>
  )
}

export default Home;

Home.propTypes = {
  open: PropTypes.bool.isRequired,
};
