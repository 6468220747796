import PropTypes from 'prop-types'

const ServiceTitle = ({
  title,
  icon
}) => {
  return (
    <div className='container-title'>
      {icon ? <img className={'icon-title-service'} src={icon} alt={title} /> : <></>}
      <h1 className='title-service'>{title}</h1>
    </div>
  )
}

export default ServiceTitle

ServiceTitle.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
}
