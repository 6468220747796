import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Services from './pages/Services';
import Queries from './pages/Queries';
import Maternity from './pages/Maternity';
import Specialties from './pages/Specialties';
import IntensiveCare from './pages/IntensiveCare';
import Internment from './pages/Internment';
import Emergencies from './pages/Emergencies';
import DiagnosticStudies from './pages/DiagnosticStudies';
import CareAndPrevention from './pages/CareAndPrevention';
import Plans from './pages/Plans';
import Installations from './pages/Installations';
import Admission from './pages/Admission';
import Contact from './pages/Contact';
import History from './pages/History';
import Footer from './components/Footer';
import './App.css';
import { useState } from 'react';

function App() {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <Header open={open} handleClick={handleClick} />
      <Routes>
        <Route path="/" element={<Home open={open} />} />
        <Route path="/servicios" element={<Services open={open} setOpen={setOpen} />} />
        <Route path="/servicios/consultas" element={<Queries open={open} setOpen={setOpen} />} />
        <Route path="/servicios/maternidad" element={<Maternity open={open} setOpen={setOpen} />} />
        <Route path="/servicios/especialidades" element={<Specialties open={open} setOpen={setOpen} />} />
        <Route path="/servicios/cuidados-intensivos" element={<IntensiveCare open={open} setOpen={setOpen} />} />
        <Route path="/servicios/internacion" element={<Internment open={open} setOpen={setOpen} />} />
        <Route path="/servicios/emergencias" element={<Emergencies open={open} setOpen={setOpen} />} />
        <Route path="/servicios/estudios-diagnosticos" element={<DiagnosticStudies open={open} setOpen={setOpen} />} />
        <Route path="/servicios/cuidado-y-prevencion" element={<CareAndPrevention open={open} setOpen={setOpen} />} />
        <Route path="/planes" element={<Plans open={open} setOpen={setOpen} />} /> 
        <Route path="/instalaciones" element={<Installations open={open} setOpen={setOpen} />} />
        <Route path="/admision" element={<Admission open={open} setOpen={setOpen} />} /> 
        <Route path="/contacto" element={<Contact open={open} setOpen={setOpen} />} /> 
        <Route path="/nuestra-historia" element={<History open={open} setOpen={setOpen} />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App
