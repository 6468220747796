import mailIcon from '../assets/contact/mailIcon.png';
import phoneIcon from '../assets/contact/phoneIcon.png';
import whatsappButton from '../assets/contact/whatsappButton.png';
import facebookIcon2 from '../assets/contact/facebookIcon2.png';
import instagramIcon2 from '../assets/contact/instagramIcon2.png';
import locationIcon from '../assets/contact/locationIcon.png';
import contactPhoto from '../assets/contact/contactPhoto.png';
import logo2 from '../assets/contact/logo2.png';
import Title from '../components/Title';
import Logo from '../components/Logo';
import ContactList from '../components/ContactList';
import PropTypes from 'prop-types';
import MenuContainer from '../components/MenuContainer';
import { Helmet } from 'react-helmet';
import homeImage from '../assets/home/homeImage.png';

const Contact =  ({ open }) => {
  const title = 'Contáctanos';
  
  return (
    <>
    <Helmet>
      <title>Clínica Copacabana | Contacto</title>
      <meta
        name="description"
        content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas."
      />
      <meta name="keywords" content="cochabamba, clinica, salud, hospital, doctores, medicos, emergencias" />
      <meta name="robots" content="index, follow"/>
      <meta name="googlebot" content="index, follow"/>
      <link rel="canonical" href={`${process.env.REACT_APP_FRONTENDURL}`} />
      <meta property="og:title" content="Clínica Copacabana" />
      <meta property="og:description" content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas." />
      <meta property="og:img" content={`${process.env.REACT_APP_FRONTENDURL}/${homeImage}`} />
      <meta property="og:url" content={`${process.env.REACT_APP_FRONTENDURL}/admisiones`} />
    </Helmet>
    {!open ? (
    <section className="contact-container">
      <div className="contact-content-left">
        <Title title={title} />
        <ContactList title={title} locationIcon={locationIcon} phoneIcon={phoneIcon} mailIcon={mailIcon} whatsappButton={whatsappButton} facebookIcon={facebookIcon2} instagramIcon={instagramIcon2} />
      </div>
      <img src={contactPhoto} alt="Foto de contacto" className="contact-content-right" />
    </section>) : 
    (
      <MenuContainer />
    )} 
    </>
  )
}

export default Contact;

Contact.propTypes = {
  open: PropTypes.bool.isRequired,
};
