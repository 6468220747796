import maternityPlanLogo from '../assets/plans/maternityPlanLogo.png' ;
import plansPhoto from '../assets/plans/plansPhoto.png' ;
import whatsappInformationIcon from '../assets/plans/whatsappInformationIcon.png' ;
import ContainerInformation from '../components/ContainerInformation' ;
import ContainerInformationImages from '../components/ContainerInformationImages' ;
import PropTypes from 'prop-types';
import MenuContainer from '../components/MenuContainer';
import { Helmet } from 'react-helmet';
import homeImage from '../assets/home/homeImage.png';

const  MaternityPlan  =  ({ open }) => {
  const title = 'Planes y Medicina Preventiva';
  const paragraph = ['CESÁREA', ['2 Días de internación.', 'Habitación individual privada, (con TV por cable, baño privado y ducha).', 'Medicamentos.', 'Equipo médico completo (cirujano, anestesista, pediatra, instrumentadora y ayudante).', 'Quirófano.', 'Nursery/Cuidados del recién nacido.', 'Curación post quirúrgica.', 'Equipo de Oxígeno.', 'Instrumental Especializado.', 'Asesoramiento en primeros cuidados para el bebé.', 'Primera consulta pediátrica.']];
  const paragraph2 = ['PARTO NORMAL', ['1 Día de internación.', 'Habitación individual privada, (con TV por cable, baño privado y ducha).', 'Equipo médico completo (cirujano, anestesista, pediatra, instrumentadora y ayudante).', 'Sala de Partos', 'Nursery/Cuidados del recién nacido.', 'Medicamentos.', 'Equipo de Oxígeno.', 'Asesoramiento en primeros cuidados para el bebé.', 'Primera consulta pediátrica.']];
  const paragraph3 = ['PARTO NORMAL (sin dolor)', ['1 Día de internación.', 'Habitación individual privada, (con TV por cable, baño privado y ducha).', 'Equipo médico completo (cirujano, anestesista, pediatra, instrumentadora y ayudante).', 'Sala de Partos', 'Nursery/Cuidados del recién nacido.', 'Medicamentos.', 'Equipo de Oxígeno.', 'Analgesia', 'Asesoramiento en primeros cuidados para el bebé.', 'Primera consulta pediátrica.']];

  return (
    <>
    <Helmet>
      <title>Clínica Copacabana | Planes</title>
      <meta
        name="description"
        content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas."
      />
      <meta name="keywords" content="cochabamba, clinica, salud, hospital, doctores, medicos, emergencias" />
      <meta name="robots" content="index, follow"/>
      <meta name="googlebot" content="index, follow"/>
      <link rel="canonical" href={`${process.env.REACT_APP_FRONTENDURL}`} />
      <meta property="og:title" content="Clínica Copacabana" />
      <meta property="og:description" content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas." />
      <meta property="og:img" content={`${process.env.REACT_APP_FRONTENDURL}/${homeImage}`} />
      <meta property="og:url" content={`${process.env.REACT_APP_FRONTENDURL}/admisiones`} />
    </Helmet>
    {!open ? (
    <section className="installations-container">
      <ContainerInformationImages isServiceTitle={true} title={title} paragraph={paragraph} photo={plansPhoto} iconContact={whatsappInformationIcon} logo={maternityPlanLogo} />
      <div className="plans-container">
        <ContainerInformation paragraph={paragraph2} />
        <ContainerInformation paragraph={paragraph3} />
      </div>
    </section>) : 
    (
      <MenuContainer />
    )}
    </>
  )
}

export default MaternityPlan;

MaternityPlan.propTypes = {
  open: PropTypes.bool.isRequired,
};
