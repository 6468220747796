import installationsPhoto from '../assets/installations/installationsPhoto.png';
import installationsPhotoEmergencies from '../assets/installations/emergencias.jpg';
import installationsPhotoWaitingRoom from '../assets/installations/sala de espera.jpg';
import installationsPhotoTomographie from '../assets/installations/tomografo1.jpg';
import ReturnPage from '../components/ReturnPage';
import ContainerInformationImages from '../components/ContainerInformationImages';
import PropTypes from 'prop-types';
import MenuContainer from '../components/MenuContainer';
import { Helmet } from 'react-helmet';
import homeImage from '../assets/home/homeImage.png';

const Installations = ({ open }) => {
  const title = 'Instalaciones';
  const paragraph = ['Nuestras instalaciones están diseñadas para brindar toda la comodidad y confort a nuestros pacientes.', 'Contamos con diferentes ambientes, sala de internación, sala de imagenología, área de atención al paciente, consulta externa, sala de emergencias.'];
  const images = [installationsPhoto, installationsPhotoEmergencies, installationsPhotoWaitingRoom, installationsPhotoTomographie]
  return (
    <>
    <Helmet>
      <title>Clínica Copacabana | Instalaciones</title>
      <meta
        name="description"
        content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas."
      />
      <meta name="keywords" content="cochabamba, clinica, salud, hospital, doctores, medicos, emergencias" />
      <meta name="robots" content="index, follow"/>
      <meta name="googlebot" content="index, follow"/>
      <link rel="canonical" href={`${process.env.REACT_APP_FRONTENDURL}`} />
      <meta property="og:title" content="Clínica Copacabana" />
      <meta property="og:description" content="Clínica Copacabana te ofrece una amplia variedad de servicios de salud de alta calidad, desde consultas médicas hasta tratamientos especializados. Nuestro equipo de médicos altamente capacitados está disponible para ayudar a los pacientes en cualquier momento, incluyendo emergencias médicas." />
      <meta property="og:img" content={`${process.env.REACT_APP_FRONTENDURL}/${homeImage}`} />
      <meta property="og:url" content={`${process.env.REACT_APP_FRONTENDURL}/admisiones`} />
    </Helmet>
    {!open ? (
    <section className="installations-container">
      <ReturnPage />
      <ContainerInformationImages
        title={title}
        paragraph={paragraph}
        photo={images}
      />
    </section>) : 
    (
      <MenuContainer />
    )}
    </>
  )
}

export default Installations;

Installations.propTypes = {
  open: PropTypes.bool.isRequired,
};
